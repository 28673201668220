/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ENewsIdentitySourceType {
    EMAIL = 'email',
    EMAIL_HASHED = 'email-hashed',
    NPI = 'npi',
    NPI_HASHED = 'npi-hashed',
    DGID = 'dgid',
}
