/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EndemicType {
    NONE = 'none',
    DTC = 'dtc',
    HCP = 'hcp',
    EHR = 'ehr',
}
